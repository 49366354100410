<template>
  <v-container id="tipos-servicos-section" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-toolbox-outline"
          inline
          class="px-5 py-4 ml-0"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">
              Tipos de serviços
            </div>
          </template>

         <v-btn
            id="btn-novo-tipo-servicos"
            color="success"
            dark
            elevation="1"
            class="mt-5"
            style="float:left"
            relative
            text
            medium
            @click="$router.push('/administracao/tipos-servicos/add')"
          >
            <v-icon left size="30px">
              mdi-plus-circle
            </v-icon>
            Novo tipo de serviços
          </v-btn>

          <v-text-field
            id="tipos-servicos-search"
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto mt-4 break-search bs-tipos-servicos"
            label="Procurar"
            hide-details
            single-line
            style="max-width: 250px;"
          />

          <v-divider class="mt-10" />

          <v-data-table
            class="tipos-servicos-table"
            :headers="[
              {
                text: 'Código',
                value: 'codigo'
              },
              {
                text: 'Nome do serviço',
                value: 'nomeServico'
              },
              {
                text: 'Prazo regulado',
                value: 'prazoRegulado'
              },
              {
                text: 'Artigo REN 1000',
                value: 'artigoRen1000'
              },
              {
                sortable: false,
                text: 'Ações',
                value: 'actions',
                class: 'pl-4'
              }
            ]"
            :items="tiposServicos"
            :search.sync="search"
            :headerProps="{
              sortByText: 'Ordenar Por'
            }"
            :footer-props="{
              itemsPerPageText: 'Itens por Página'
            }"
            :loading="loading"
            :hide-default-header="loading"
            :hide-default-footer="loading"
            :mobile-breakpoint="800"
            no-data-text="Nenhum tipo de serviços encontrado"
            no-results-text="Nenhum registro correspondente encontrado"
          > 
            <template v-slot:[`item.id`]="{ item }">
              {{ item.id | addLeadingZero }}
            </template> 
            <template v-slot:[`item.status`]="{ item }">
              <general-status :status="item.status" />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                id="edit-tipos-servicos"
                min-width="0"
                class="px-1"
                fab
                icon
                x-small
                @click="$router.push(`/administracao/tipos-servicos/edit/${item.codigo}`)"
              >
                <v-icon small>
                  mdi-pencil-outline
                </v-icon>
              </v-btn>
            </template>  
          </v-data-table>
        </base-material-card>  
      </v-col>
    </v-row>  
  </v-container>
</template>

<script>

import tourMixins from '@/mixins/tourMixins';
import TiposServicosService from '../../../services/TiposServicosService';

export default {
  name: 'TiposServicosIndex',
  mixins: [tourMixins],
  components: {
    GeneralStatus: () => import('@/components/general/GeneralStatus.vue'),
  },
  data: () => ({
    tiposServicos: [],
    search: '',
    arrTour: [
      {
        element: '#btn-novo-tipo-servicos',
        intro: 'Clicando neste botão você poderá cadastrar novos tipos de serviço.',
        scrollTo: "tooltip",
        position: 'right'
      },
      {
        element: '#tipos-servicos-search',
        intro: 'Neste campo você poderá pesquisar por tipos de serviços já cadastrados.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
    ],
    loading: false
  }),
  mounted() {
    this.getTiposServicos();
  },
  methods: {
    getTiposServicos() {
      this.loading = true;
      this.tiposServicos = [];

      TiposServicosService.getAll()
        .then((data) => {
          data.forEach((tipoServico) => {
              this.tiposServicos.push({
                codigo: tipoServico.codigo,
                nomeServico: tipoServico.servico,
                prazoRegulado: tipoServico.detalhe_servico.prazo_regulado_raw,
                artigoRen1000: tipoServico.detalhe_servico.artigo_ren_1000
              });
          });

          this.tiposServicos.sort((a, b) => a.codigo - b.codigo);
        })
        .catch((err) => {
          this.$toast.error("Erro ao recuperar tipos de serviços.", "", {
            position: "topRight",
          });
          throw err;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      let arrTourFormatted = this.importacoes.length ? this.arrTour : this.arrTour.splice(0,2);
      this.iniciarTour(arrTourFormatted);
    }
  }
};
</script>

<style>
@media screen and (min-width: 800px) {
  .tipos-servicos-table table tr td:nth-child(2) {
    max-width: 200px;
  }

  .tipos-servicos-table table tr td:nth-child(7) {
    white-space: nowrap !important;
  }
}

@media screen and (max-width: 480px) {
  .bs-tipos-servicos {
    width: 235.86px !important;
  }
}
</style>