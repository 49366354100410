var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"tipos-servicos-section","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-toolbox-outline","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" Tipos de serviços ")])]},proxy:true}])},[_c('v-btn',{staticClass:"mt-5",staticStyle:{"float":"left"},attrs:{"id":"btn-novo-tipo-servicos","color":"success","dark":"","elevation":"1","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.$router.push('/administracao/tipos-servicos/add')}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Novo tipo de serviços ")],1),_c('v-text-field',{staticClass:"ml-auto mt-4 break-search bs-tipos-servicos",staticStyle:{"max-width":"250px"},attrs:{"id":"tipos-servicos-search","append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{staticClass:"tipos-servicos-table",attrs:{"headers":[
            {
              text: 'Código',
              value: 'codigo'
            },
            {
              text: 'Nome do serviço',
              value: 'nomeServico'
            },
            {
              text: 'Prazo regulado',
              value: 'prazoRegulado'
            },
            {
              text: 'Artigo REN 1000',
              value: 'artigoRen1000'
            },
            {
              sortable: false,
              text: 'Ações',
              value: 'actions',
              class: 'pl-4'
            }
          ],"items":_vm.tiposServicos,"search":_vm.search,"headerProps":{
            sortByText: 'Ordenar Por'
          },"footer-props":{
            itemsPerPageText: 'Itens por Página'
          },"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"mobile-breakpoint":800,"no-data-text":"Nenhum tipo de serviços encontrado","no-results-text":"Nenhum registro correspondente encontrado"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("addLeadingZero")(item.id))+" ")]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"px-1",attrs:{"id":"edit-tipos-servicos","min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.$router.push(("/administracao/tipos-servicos/edit/" + (item.codigo)))}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil-outline ")])],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }